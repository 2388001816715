<template>
  <div class="flex flex-wrap">
    <transition name="fade">
      <contact-authorizations-modal v-show="modalContact" :contact="businessData.autorizacoesContato"
        @closeModal="modalContact = false"></contact-authorizations-modal>
    </transition>
    <div class="row my-4">
      <p class="title">Incluir nova proposta</p>
    </div>
    <div class="row">
      <div class="w-full bg-white border shadow rounded-lg p-4 pt-1 mb-4 lg:mb-0">
        <div class="row">
          <v-input class="w-full" name="cnpj" v-model="businessData.cnpj" :error="errors.first('cnpj')" label="CNPJ*"
            v-validate="'required|cnpj'" mask="##.###.###/####-##"></v-input>

        </div>
        <div class="row">
          <v-input class="w-full lg:w-1/2  lg:pr-4" name="nomeEmpresa" v-model="businessData.nomeEmpresa"
            :error="errors.first('nomeEmpresa')" label="Razão social/Nome fantasia do CNPJ*"
            v-validate="'required|min:2'"></v-input>

          <v-input class="w-full lg:w-1/2 lg:pr-4" name="emailContato" v-model="businessData.emailContato"
            :error="errors.first('emailContato')" label="E-mail do responsável da empresa*"
            v-validate="'required|email'"></v-input>
        </div>
        <div class="row">
          <v-input class="w-full lg:w-1/2 lg:pr-4" name="telefoneContatoDireto"
            v-model="businessData.telefoneContatoDireto" :error="errors.first('telefoneContatoDireto')"
            label="Telefone*" v-validate="'required|min:14|max:15'" :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"></v-input>
          <v-input class="w-full lg:w-1/2" name="valorEmprestimoDesejado"
            v-model.number="businessData.valorEmprestimoDesejado" :error="errors.first('valorEmprestimoDesejado')"
            label="Valor do empréstimo desejado?"
            v-validate="{ decimal: 2, min_value: 1000, max_value: businessData.microCredito ? 15000 : 600000 }"
            type="money"></v-input>
        </div>
        <div class="row">
          <v-input class="w-full lg:w-1/2 lg:pr-4" name="faturamentoMedioMensal"
            v-model.number="businessData.faturamentoMedioMensal" :error="errors.first('faturamentoMedioMensal')"
            label="Qual o faturamento mensal?" v-validate="'decimal:2|min_value:0'" type="money"></v-input>
          <v-input class="w-full lg:w-1/2" name="prazoDesejado" v-model="businessData.prazoDesejado"
            label="Quantidade de parcelas?*" itemText="id" itemValue="id" :options="instalments"
            v-validate="{ required: true, is_not: -1 }" :error="errors.first('prazoDesejado')"></v-input>
        </div>
        <v-input class="w-full" name="motivoSolicitacaoId" v-model="businessData.motivoSolicitacaoId"
          label="Qual a necessidade de crédito?*" :options="reasons" itemValue="id" itemText="descricao"
          v-validate="{ required: true, is_not: -1 }" :error="errors.first('motivoSolicitacaoId')"></v-input>

        <div class="boxes">
          <div class="boxes_group">
            <p class="radio_label">Empresa ou sócio(s) possuem restrição?*</p>
            <v-radio class="inline" name="possuiNegativacao" v-model="businessData.possuiNegativacao" label="Sim"
              :value="true" v-validate="'required'"></v-radio>
            <v-radio class="inline" name="possuiNegativacao" v-model="businessData.possuiNegativacao" label="Não"
              :value="false"></v-radio>
            <p class="text-xs text-red-600">{{ errors.first("possuiNegativacao") }}</p>
          </div>
          <div class="boxes_group">
            <p class="radio_label">Tomador pode receber e-mail caso solicitação seja negada?*</p>
            <v-radio class="inline" name="enviarEmails" v-model="businessData.enviarEmails" label="Sim" :value="true"
              v-validate="'required'"></v-radio>
            <v-radio class="inline" name="enviarEmails" v-model="businessData.enviarEmails" label="Não" :value="false">
            </v-radio>
            <p class="text-xs text-red-600">{{ errors.first("enviarEmails") }}</p>
          </div>
        </div>
        <div class="my-4">
          <v-checkbox class="my-4" name="microcredito" v-model="businessData.microCredito" label="É microcrédito?*" />
          <v-checkbox name="contact" :modelValue="contactValue" @click.native.prevent="modalContact = true"
            :label="`Aceito receber informações e contatos por ${contactText}.`" />
        </div>

        <div class="buttons">
          <v-button class="justify-center lg:justify-start" :loading="loading" @click="submit">Incluir proposta
          </v-button>
          <v-text-button class="w-full lg:my-6 lg:ml-6 flex items-center justify-center lg:justify-start"
            @click="$router.push('/parceiro/painel')">Voltar</v-text-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VRadio from '@/components/Inputs/Radio'
import VTextButton from '@/components/Buttons/TextButton'
import ContactAuthorizationsModal from '@/components/Modal/ContactAuthorizationsModal'

import Dashboard from '@/services/Partner/Dashboard'

export default {
  name: 'new-proposal',
  components: { VButton, VInput, VCheckbox, VRadio, VTextButton, ContactAuthorizationsModal },
  data() {
    return {
      loading: false,
      modalContact: false,
      reasons: [{ id: 1, descricao: 'teste' }],
      instalments: [
        { id: 6 },
        { id: 12 },
        { id: 18 },
        { id: 24 }
      ],

      businessData: {
        valorEmprestimoDesejado: '',
        prazoDesejado: -1,
        nomeEmpresa: '',
        emailContato: '',
        cnpj: '',
        telefoneContatoDireto: '',
        faturamentoMedioMensal: '',
        possuiNegativacao: null,
        motivoSolicitacaoId: -1,
        enviarEmails: true,
        microCredito: false,
        autorizacoesContato: {
          email: true,
          sms: true,
          telefone: true,
          whatsApp: true
        }
      }
    }
  },
  computed: {
    contactValue() {
      return Object.values(this.businessData.autorizacoesContato).some(v => v)
    },
    contactText() {
      const text = {
        sms: 'SMS',
        telefone: 'telefone',
        email: 'e-mail',
        whatsApp: 'WhatsApp'
      }
      if (Object.values(this.businessData.autorizacoesContato).every(v => !v)) {
        return Object.values(text).join(', ')
      }
      return Object.entries(this.businessData.autorizacoesContato).map(([key, value]) => value ? text[key] : '').filter(v => v).join(', ')
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            const grecaptcha = await this.$recaptcha('login')
            await Dashboard.newProposal({ ...this.businessData, grecaptcha })
            this.$store.dispatch('notification', { type: 'success', message: 'Proposta incluída com sucesso!' })

            history.pushState({}, null, '/parceiro/proposta-incluida');
            setTimeout(() => {
              this.$router.push('/parceiro/painel')
            }, 1000);

          } catch (error) {
            this.loading = false
            this.$store.dispatch('notificationErrors', error.response.data.erros)
            if (error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
            }
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  async mounted() {
    try {
      this.reasons = (await Dashboard.getReasons()).data.data
    } catch (error) {
      console.error(error)
    }
  },
  watch: {
    'businessData.cnpj': async function () {
      if (!this.loadingName && this.businessData.cnpj.length >= 8 && (!this.businessData.nomeEmpresa || this.businessData.cnpj.substring(0, 8) != this.partCNPJ)) {
        this.loadingName = true;
        const res = (await Dashboard.getNameRFB(this.businessData.cnpj)).data.data;

        if (res) {
          if (res.razaoSocial && (!res.nomeFantasia || res.nomeFantasia.indexOf('***') === 0)) {
            this.businessData.nomeEmpresa = res.razaoSocial;
          } else {
            this.businessData.nomeEmpresa = res.nomeFantasia;
          }

          this.partCNPJ = this.businessData.cnpj.substring(0, 8);
        }

        this.loadingName = false;
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.row
  @apply w-full flex flex-col
  @screen lg
    @apply flex-row

.title
  @apply font-bold text-xl text-secondary my-4 w-full
  @screen lg
    @apply text-2xl

.boxes
  @apply w-full flex flex-col justify-center
  @screen lg
    @apply flex-row

.boxes_group
  @apply my-3
  @screen lg
    @apply w-1/2 mx-0

.buttons
  @apply w-full flex flex-col items-center
  @screen lg
    @apply items-start flex-row

</style>
