<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <div>
            <v-checkbox class="text-sm" name="email" v-model="contact.email" label="E-mail"/>
          </div>
          <div>
            <v-checkbox class="text-sm" name="sms" v-model="contact.sms" label="SMS"/>
          </div>
          <div>
            <v-checkbox class="text-sm" name="telefone" v-model="contact.telefone" label="Telefone"/>
          </div>
          <div>
            <v-checkbox class="text-sm" name="whatsApp" v-model="contact.whatsApp" label="WhatsApp"/>
          </div>
          <v-button class="flex justify-center" size="sm" @click="close">Voltar</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import VCheckbox from '@/components/Inputs/Checkbox'

export default {
  props: ['contact'],
  components: { VButton, VCheckbox },
  data () {
    return {
      submitted: false,
      email: ''
    }
  },
  methods: {
    close () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center pb-4 pt-8 rounded-lg
  @screen lg
    @apply w-5/12

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_modal
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.label
  @apply mx-2 cursor-pointer select-none

</style>
