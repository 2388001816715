import { render, staticRenderFns } from "./ContactAuthorizationsModal.vue?vue&type=template&id=6b59e552&scoped=true&"
import script from "./ContactAuthorizationsModal.vue?vue&type=script&lang=js&"
export * from "./ContactAuthorizationsModal.vue?vue&type=script&lang=js&"
import style0 from "./ContactAuthorizationsModal.vue?vue&type=style&index=0&id=6b59e552&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b59e552",
  null
  
)

export default component.exports